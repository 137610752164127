import React from 'react';
import styled from 'styled-components';
import BigTitle from '../components/shared/text/BigTitle';
import Section from '../components/shared/wraps/Section/Section';
import SEO from '../components/seo';
import { Wrapper } from 'src/components/Sites/ServicesPages/shared/ServicesPages';
import {
  NoWrap,
} from 'src/components/shared/Animations/Animations';
import LayoutCV  from "../layouts/LayoutCV";
import {useI18next} from "gatsby-plugin-react-i18next";

const StyledBigTitle = styled(BigTitle)`
  margin-bottom: 3.5rem;
  text-align: center;
  width: 100%;
  font-size: clamp(6rem, 8vw, 12rem);
  line-height: 1;
`;


const StyledSection = styled(Section)`
  padding-left: 0;
  padding-right: 0;
`;

const Content = styled.div`
 margin-top: 3rem;
  margin-bottom: 2rem;
  padding-left: 1rem;
  padding-right: 4rem;
  *{
   margin-bottom: 1rem; 
    color: ${({ theme }) => theme.colors.grayText};
  }
  ul, ol{
    padding-left: 2rem;
    margin-bottom: 1rem;
    & li::marker{
      content: '■  ';
      font-weight: bold;
      color: ${({ theme }) => theme.colors.main};

    }
  }
  p{
    margin-bottom: 1rem;
  }
  h1, h2, h3, h4, h5, h6{
    color: ${({ theme }) => theme.colors.black4};
    font-size: 18px;
  }
`

const Container = styled.div`
  margin: 0 auto;
  padding: 20px;
  max-width: 800px;
  font-family: 'Arial', sans-serif;
  line-height: 1.6;
`;

const Box = styled.section`
  margin-bottom: 30px;
`;

const Heading1 = styled.h1`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
`;

const Heading2 = styled.h2`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
`;

const Paragraph = styled.p`
  margin-bottom: 15px;
`;

const List = styled.ul`
  list-style-type: disc;
  padding-left: 20px;
`;

const ListItem = styled.li`
  margin-bottom: 10px;
`;

const Link = styled.a`
  color: #3498db;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;


const PrivacyPolicy = ({  location: { pathname: path } }) => {


const {language} = useI18next()
  return (
    <LayoutCV path={path} id='oferty-pracy'>
      <SEO title="Polityka Prywatności" />
      <StyledSection nonGrid site nonFlex first>
        <Wrapper nonGrid>
          <StyledBigTitle>
            {language === "pl" ? <NoWrap>
              Polityka prywatności
            </NoWrap> :<NoWrap>
              Privacy Policy
            </NoWrap> }
          </StyledBigTitle>
        </Wrapper>

        <Wrapper nonGrid>
          <>
            {language === "pl" ? (<>
              <Container>
                <Box>
                  <Heading1>Polityka prywatności</Heading1>
                  <Paragraph>
                    Polityka prywatności opisuje zasady przetwarzania przez nas informacji na Twój temat, w tym danych osobowych oraz ciasteczek, czyli tzw. cookies.
                  </Paragraph>
                </Box>

                <Box>
                  <Heading2>1. Informacje ogólne</Heading2>
                  <List>
                    <ListItem>Niniejsza polityka dotyczy Serwisu www, funkcjonującego pod adresem url: <Link href="https://makadu.pl">makadu.pl</Link></ListItem>
                    <ListItem>Operatorem serwisu oraz Administratorem danych osobowych jest: Kamil Nowotny, os. Rzeczypospolitej 98/10, 61-392 Poznań</ListItem>
                    <ListItem>Adres kontaktowy poczty elektronicznej operatora: <Link href="mailto:biuro@makadu.pl">biuro@makadu.pl</Link></ListItem>
                    <ListItem>Operator jest Administratorem Twoich danych osobowych w odniesieniu do danych podanych dobrowolnie w Serwisie.</ListItem>
                    <ListItem>Serwis wykorzystuje dane osobowe w następujących celach:
                      <List>
                        <ListItem>Prezentacja oferty lub informacji</ListItem>
                      </List>
                    </ListItem>
                    <ListItem>Serwis realizuje funkcje pozyskiwania informacji o użytkownikach i ich zachowaniu w następujący sposób:
                      <List>
                        <ListItem>Poprzez dobrowolnie wprowadzone w formularzach dane, które zostają wprowadzone do systemów Operatora.</ListItem>
                        <ListItem>Poprzez zapisywanie w urządzeniach końcowych plików cookie (tzw. „ciasteczka”).</ListItem>
                      </List>
                    </ListItem>
                  </List>
                </Box>

                <Box>
                  <Heading2>2. Wybrane metody ochrony danych stosowane przez Operatora</Heading2>
                  <List>
                    <ListItem>Miejsca logowania i wprowadzania danych osobowych są chronione w warstwie transmisji (certyfikat SSL).</ListItem>
                    <ListItem>Hasła użytkowników są przechowywane w postaci hashowanej.</ListItem>
                    <ListItem>Operator okresowo zmienia swoje hasła administracyjne.</ListItem>
                    <ListItem>Operator regularnie wykonuje kopie bezpieczeństwa.</ListItem>
                    <ListItem>Operator regularnie aktualizuje oprogramowanie.</ListItem>
                  </List>
                </Box>

                <Box>
                  <Heading2>3. Hosting</Heading2>
                  <List>
                    <ListItem>Serwis jest hostowany na serwerach operatora: cyberFolks.pl</ListItem>
                    <ListItem>Firma hostingowa prowadzi logi na poziomie serwera.</ListItem>
                    <ListItem>Zapisowi mogą podlegać:
                      <List>
                        <ListItem>zasoby określone identyfikatorem URL</ListItem>
                        <ListItem>czas nadejścia zapytania</ListItem>
                        <ListItem>czas wysłania odpowiedzi</ListItem>
                        <ListItem>nazwę stacji klienta</ListItem>
                        <ListItem>informacje o błędach przy realizacji transakcji HTTP</ListItem>
                        <ListItem>adres URL strony poprzednio odwiedzanej</ListItem>
                        <ListItem>informacje o przeglądarce użytkownika</ListItem>
                        <ListItem>informacje o adresie IP</ListItem>
                      </List>
                    </ListItem>
                  </List>
                </Box>

                <Box>
                  <Heading2>4. Twoje prawa i dodatkowe informacje o sposobie wykorzystania danych</Heading2>
                  <List>
                    <ListItem>Administrator ma prawo przekazywać dane osobowe innym odbiorcom.</ListItem>
                    <ListItem>Twoje dane osobowe są przetwarzane nie dłużej, niż to konieczne.</ListItem>
                    <ListItem>Masz prawo do dostępu, sprostowania, usunięcia, ograniczenia przetwarzania oraz przenoszenia danych.</ListItem>
                    <ListItem>Przysługuje Ci prawo do złożenia skargi do Prezesa Urzędu Ochrony Danych Osobowych.</ListItem>
                    <ListItem>Podanie danych osobowych jest dobrowolne, lecz niezbędne do obsługi Serwisu.</ListItem>
                  </List>
                </Box>

                <Box>
                  <Heading2>5. Informacje w formularzach</Heading2>
                  <List>
                    <ListItem>Serwis zbiera informacje podane dobrowolnie przez użytkownika.</ListItem>
                    <ListItem>Serwis może zapisać informacje o parametrach połączenia.</ListItem>
                    <ListItem>Dane w formularzu są przetwarzane zgodnie z funkcją formularza.</ListItem>
                  </List>
                </Box>

                <Box>
                  <Heading2>6. Logi Administratora</Heading2>
                  <Paragraph>Informacje o zachowaniu użytkowników mogą podlegać logowaniu w celu administrowania serwisem.</Paragraph>
                </Box>

                <Box>
                  <Heading2>7. Istotne techniki marketingowe</Heading2>
                  <List>
                    <ListItem>Operator stosuje analizę statystyczną ruchu na stronie, poprzez Google Analytics.</ListItem>
                    <ListItem>Usługa bazuje na wykorzystaniu ciasteczek.</ListItem>
                  </List>
                </Box>

                <Box>
                  <Heading2>8. Informacja o plikach cookies</Heading2>
                  <List>
                    <ListItem>Serwis korzysta z plików cookies.</ListItem>
                    <ListItem>Cookies to pliki tekstowe przechowywane w urządzeniu końcowym Użytkownika.</ListItem>
                    <ListItem>Cookies są wykorzystywane w następujących celach:
                      <List>
                        <ListItem>utrzymanie sesji użytkownika po zalogowaniu</ListItem>
                        <ListItem>realizacji celów marketingowych</ListItem>
                      </List>
                    </ListItem>
                    <ListItem>W ramach Serwisu stosowane są pliki „sesyjne” i „stałe”.</ListItem>
                    <ListItem>Użytkownik może zarządzać plikami cookies poprzez ustawienia przeglądarki.</ListItem>
                  </List>
                </Box>

                <Box>
                  <Heading2>9. Zarządzanie plikami cookies</Heading2>
                  <List>
                    <ListItem>Użytkownik może zmienić ustawienia cookies w przeglądarce.</ListItem>
                    <ListItem>Instrukcje dla przeglądarek: <Link
                      href="https://support.microsoft.com/pl-pl/microsoft-edge/wy%C5%9Bwietlanie-i-usuwanie-historii-przegl%C4%85darki-w-programie-microsoft-edge-00cf7943-a9e1-975a-a33d-ac10ce454ca4">Edge</Link>,<Link
                      href="https://support.microsoft.com/pl-pl/topic/jak-usun%C4%85%C4%87-pliki-cookie-w-programie-internet-explorer-bca9446f-d873-78de-77ba-d42645fa52fc"> Internet Explorer</Link>, <Link
                      href="https://support.google.com/chrome/answer/95647?hl=pl">Chrome</Link>, <Link
                      href="https://support.apple.com/pl-pl/guide/safari/sfri11471/mac">Safari</Link>, <Link
                        href="https://support.mozilla.org/pl/kb/wzmocniona-ochrona-przed-sledzeniem-firefox-desktop?redirectslug=W%C5%82%C4%85czanie+i+wy%C5%82%C4%85czanie+obs%C5%82ugi+ciasteczek&redirectlocale=pl">Firefox</Link>, <Link
                        href="https://help.opera.com/pl/latest/web-preferences/#cookies">Opera</Link>.</ListItem>
                    <ListItem>Instrukcje dla urządzeń mobilnych: <Link href="https://support.google.com/chrome/answer/95647?hl=pl">Android</Link>, <Link href="https://support.microsoft.com/pl-pl/windows/windows-phone-7-3ebc303c-59c0-d367-3995-f258b184fabb">iOS</Link>,<Link href="https://support.microsoft.com/pl-pl/windows/windows-phone-7-3ebc303c-59c0-d367-3995-f258b184fabb"> Windows Phone</Link>.</ListItem>
                  </List>
                </Box>
              </Container>
            </>) : (<>
              <Container>
                <Box>
                  <Heading1>Privacy Policy</Heading1>
                  <Paragraph>
                    The Privacy Policy describes the principles of processing information about you, including personal data and cookies.
                  </Paragraph>
                </Box>

                <Box>
                  <Heading2>1. General Information</Heading2>
                  <List>
                    <ListItem>This policy applies to the website operating at: <Link href="https://makadu.pl">makadu.pl</Link></ListItem>
                    <ListItem>The website operator and personal data administrator is: Kamil Nowotny, os. Rzeczypospolitej 98/10, 61-392 Poznań</ListItem>
                    <ListItem>Contact email address of the operator: <Link href="mailto:biuro@makadu.pl">biuro@makadu.pl</Link></ListItem>
                    <ListItem>The operator is the administrator of your personal data in relation to the data provided voluntarily on the website.</ListItem>
                    <ListItem>The website uses personal data for the following purposes:
                      <List>
                        <ListItem>Presentation of an offer or information</ListItem>
                      </List>
                    </ListItem>
                    <ListItem>The website collects information about users and their behavior in the following ways:
                      <List>
                        <ListItem>Through voluntarily entered data in forms that are entered into the operator's systems.</ListItem>
                        <ListItem>By saving cookies on end devices (so-called "cookies").</ListItem>
                      </List>
                    </ListItem>
                  </List>
                </Box>

                <Box>
                  <Heading2>2. Selected Data Protection Methods Used by the Operator</Heading2>
                  <List>
                    <ListItem>Login and personal data entry points are protected at the transmission layer (SSL certificate).</ListItem>
                    <ListItem>User passwords are stored in a hashed form.</ListItem>
                    <ListItem>The operator periodically changes its administrative passwords.</ListItem>
                    <ListItem>The operator regularly creates backups.</ListItem>
                    <ListItem>The operator regularly updates its software.</ListItem>
                  </List>
                </Box>

                <Box>
                  <Heading2>3. Hosting</Heading2>
                  <List>
                    <ListItem>The website is hosted on servers operated by: cyberFolks.pl</ListItem>
                    <ListItem>The hosting company keeps logs at the server level.</ListItem>
                    <ListItem>Logs may include:
                      <List>
                        <ListItem>resources identified by a URL</ListItem>
                        <ListItem>time of request arrival</ListItem>
                        <ListItem>time of response sending</ListItem>
                        <ListItem>client station name</ListItem>
                        <ListItem>information about errors during the HTTP transaction</ListItem>
                        <ListItem>URL of the previously visited page</ListItem>
                        <ListItem>information about the user's browser</ListItem>
                        <ListItem>information about the IP address</ListItem>
                      </List>
                    </ListItem>
                  </List>
                </Box>

                <Box>
                  <Heading2>4. Your Rights and Additional Information on Data Usage</Heading2>
                  <List>
                    <ListItem>The administrator has the right to transfer personal data to other recipients.</ListItem>
                    <ListItem>Your personal data is processed no longer than necessary.</ListItem>
                    <ListItem>You have the right to access, rectify, delete, limit the processing, and transfer your data.</ListItem>
                    <ListItem>You have the right to file a complaint with the President of the Personal Data Protection Office.</ListItem>
                    <ListItem>Providing personal data is voluntary but necessary for the operation of the website.</ListItem>
                  </List>
                </Box>

                <Box>
                  <Heading2>5. Information in Forms</Heading2>
                  <List>
                    <ListItem>The website collects information voluntarily provided by the user.</ListItem>
                    <ListItem>The website may save information about connection parameters.</ListItem>
                    <ListItem>Data in the form is processed according to the form's function.</ListItem>
                  </List>
                </Box>

                <Box>
                  <Heading2>6. Administrator's Logs</Heading2>
                  <Paragraph>User behavior information may be logged for website administration purposes.</Paragraph>
                </Box>

                <Box>
                  <Heading2>7. Important Marketing Techniques</Heading2>
                  <List>
                    <ListItem>The operator uses statistical analysis of site traffic via Google Analytics.</ListItem>
                    <ListItem>The service is based on the use of cookies.</ListItem>
                  </List>
                </Box>

                <Box>
                  <Heading2>8. Information about Cookies</Heading2>
                  <List>
                    <ListItem>The website uses cookies.</ListItem>
                    <ListItem>Cookies are text files stored on the user's end device.</ListItem>
                    <ListItem>Cookies are used for the following purposes:
                      <List>
                        <ListItem>Maintaining user sessions after logging in</ListItem>
                        <ListItem>Implementing marketing objectives</ListItem>
                      </List>
                    </ListItem>
                    <ListItem>The website uses both "session" and "persistent" cookies.</ListItem>
                    <ListItem>Users can manage cookies through their browser settings.</ListItem>
                  </List>
                </Box>

                <Box>
                  <Heading2>9. Managing Cookies</Heading2>
                  <List>
                    <ListItem>Users can change cookie settings in their browsers.</ListItem>
                    <ListItem>Instructions for browsers: <Link href="https://support.microsoft.com/en-us/microsoft-edge/view-and-delete-browser-history-in-microsoft-edge-00cf7943-a9e1-975a-a33d-ac10ce454ca4">Edge</Link>, <Link href="https://support.microsoft.com/en-us/topic/how-to-delete-cookie-files-in-internet-explorer-bca9446f-d873-78de-77ba-d42645fa52fc">Internet Explorer</Link>, <Link href="https://support.google.com/chrome/answer/95647?hl=en">Chrome</Link>, <Link href="https://support.apple.com/en-us/guide/safari/sfri11471/mac">Safari</Link>, <Link href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">Firefox</Link>, <Link href="https://help.opera.com/en/latest/web-preferences/#cookies">Opera</Link>.</ListItem>
                    <ListItem>Instructions for mobile devices: <Link href="https://support.google.com/chrome/answer/95647?hl=en">Android</Link>, <Link href="https://support.microsoft.com/en-us/windows/windows-phone-7-3ebc303c-59c0-d367-3995-f258b184fabb">iOS</Link>, <Link href="https://support.microsoft.com/en-us/windows/windows-phone-7-3ebc303c-59c0-d367-3995-f258b184fabb">Windows Phone</Link>.</ListItem>
                  </List>
                </Box>
              </Container>
            </>)}
          </>
        </Wrapper>
      </StyledSection>
    </LayoutCV>
  );
};

export default PrivacyPolicy;
